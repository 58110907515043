import { render, staticRenderFns } from "./elektronik-insurance-basic.vue?vue&type=template&id=40a3ac96&scoped=true&"
import script from "./elektronik-insurance-basic.vue?vue&type=script&lang=ts&"
export * from "./elektronik-insurance-basic.vue?vue&type=script&lang=ts&"
import style0 from "./elektronik-insurance-basic.vue?vue&type=style&index=0&id=40a3ac96&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40a3ac96",
  null
  
)

export default component.exports